import React, { useState, useEffect } from "react";
import Routes from "./routes";
import "react-toastify/dist/ReactToastify.css";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ptBR } from "@material-ui/core/locale";
import { withOneTabEnforcer } from "react-one-tab-enforcer"


const App = () => {
  const [locale, setLocale] = useState();

  const theme = createTheme(
    {
      scrollbarStyles: {
        "&::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
          backgroundColor: "#606060",
        },
      },
      palette: {
        //primary: { main: "#2576d2" },
        //primary: { main: "#ccd225" },
        //primary: { main: "#d2ad25" },
        //primary: { main: "#d22553" },
        primary:  { 
          main: "#2553d2",
          dark: '#002884',},
        primary2: { main: "#4e25d2"},
        primary3: { main: "#d24e25"},
        primary4: { main: "#25d24e"},
        primary5: { main: "#c72929"},
        primary6: { main: "#c72978"},
        primary7: { main: "#2978c7"},
        primary8: { main: "#c77829"},

      },
    },
    locale
  );

  useEffect(() => {
    const i18nlocale = localStorage.getItem("i18nextLng");
    const browserLocale =
      i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

    if (browserLocale === "ptBR") {
      setLocale(ptBR);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  );
};

//export default App;
export default withOneTabEnforcer()(App)